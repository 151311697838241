@import "../scss/all";

$progress-default-border-color: #c7d4e4;
$progress-step-size: rem(14);
$progress-step-font-size: rem(11);
$progress-bar-width: rem(2);

.ProgressBar {
  position: relative;
  top: 0px;
  padding-bottom: 5px;
}

.ProgressBarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.ProgressBarRange {
  background-color: $progress-default-border-color;
  font-family: $font-family-heading;
  height: .14rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc((#{extractVar(#{--progress-width})} - 1) / (#{extractVar(#{--progress-steps})} - 1) * 100%);
    background: color(secondary);
    content: "";
    transition: width $base-transition-speed linear;
  }
}

.ProgressBarStep {
  background-color: color(white);
  position: relative;

  &:first-of-type {
    left: rem(-2);
  }

  &:last-of-type {
    left: rem(1)
  }
}

.ProgressBarStepCompleted {
  .ProgressBarMarker {
    background-color: extractVar(#{--ui-color});
    border: $progress-bar-width solid color(tertiary);
  }
}

.ProgressBarStepActive {
  .ProgressBarMarker {
    background: color(white);
    border: $progress-bar-width solid color(tertiary);
  }

  svg {
    display: none;
  }
}

.ProgressBarMarker {
  background: #F4E6E4;
  border-radius: 100%;
  border: $progress-bar-width solid #F4E6E4;
  display: table;
  height: 2rem;
  width: 2rem;
}

.ProgressBarLabel {
  color: color(black);
  font-family: $font-family-body;
  font-weight: $font-weight-medium;
  font-size: $progress-step-font-size;
  left: 50%;
  letter-spacing: rem(1);
  padding-top: $progress-step-size / 2;
  position: absolute;
  text-transform: uppercase;
  top: -29px;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.ProgressBarIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
