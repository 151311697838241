// ==== Breakpoints =========================
// Functions
// ==========================================

@function bp($v) {
  @return map-get($breakpoints, $v);
}

@function tp($v) {
  @return map-get($tweakpoints, $v);
}



// ==== Breakpoints =========================
// Shorthand mixins
// ==========================================

@mixin min-height($hmin) {
  @media only screen and (min-height: ($hmin / 16) + em) {
    @content;
  }
}

@mixin max-height($hmax) {
  @media only screen and (max-height: (($hmax - 1) / 16) + em) {
    @content;
  }
}

@mixin min-width($wmin) {
  @media only screen and (min-width: ($wmin / 16) + em) {
    @content;
  }
}

@mixin max-width($wmax) {
  @media only screen and (max-width: (($wmax - 1) / 16) + em) {
    @content;
  }
}