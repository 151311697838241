// Overrides, stuff I'm generally not proud of.

.carousel {
  padding-bottom: $base-gutter * 2;

  .slide {
    background-color: transparent !important;
  }

  .slide {
    h3,
    p {
      opacity: 0;
      transition: all 350ms linear 300ms;
    }

    &:first-of-type {
      h3,
      p {
        padding-left: 10%; // WHY? To center the content on the first slide
      }
    }
  }

  .selected {
    h3,
    p {
      opacity: 1;
    }
  }

  .dot {
    width: rem(6) !important;
    height: rem(6) !important;
    margin-right: $base-gutter !important;
    box-shadow: none !important;
    background-color: #FFC600 !important;
    opacity: 0.8;
    outline: 0;
    vertical-align: middle;
  }

  .control-dots {
    .selected {
      width: rem(10) !important;
      height: rem(10) !important;
    }
  }
}
