@import "../scss/all";

.DiscountStatus {
  background: #EEEEEF;
  padding: $base-gutter;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 100;

  img {
    width: 2rem;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(#{extractVar(#{--discount-width})} / 4 * 100%);
    height: 100%;
    background: rgba(color(tertiary), calc(#{extractVar(#{--discount-width})} / 4)) ;
    content: '';
    transition: width $base-transition-speed * 2 ease-in-out;
  }
}

.DiscountStatusText {
  text-transform: uppercase;
  letter-spacing: em(1);
  position: relative;
  z-index: 1;
  font-weight: normal;

  .CountMoreText {
    font-weight: $font-weight-semibold;
  }
}

.DiscountStatusTextCompleted {
  color: color(white);
}