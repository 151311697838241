@import "../scss/all";

.ThemeTitle {
    font-family: $font-family-heading;
    font-weight: $font-weight-medium;
    font-size: 1.2em;
    text-align: center;
    padding: 1rem .5rem .5rem .5rem;
}

.ThemeDescription {
    font-family: $font-family-body;
    font-weight: normal;
    font-size: .9rem;
    letter-spacing: .02rem;
    padding: 0 .5rem;
    text-align:center;
    margin-bottom: 1rem;
}