@import '../scss/all';

.StylistNote {
  .OutfitTitle {
    font-size: em(24);
  }
  img {
    margin-right: $base-gutter;
    border-radius: rem(6);
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: top;
  }
  p {
    margin-top: $base-gutter / 2;
    margin-bottom: 0;
    font-family: $font-family-body;
    font-weight: normal;
    font-size: em(16);
  }
  .hr {
    opacity: 0.25;
    background: #d8d8d8;
    border: 1px solid #979797;
    height: 1px;
    width: 67%;
    margin: 0 auto;
    margin-bottom: 26px;
  }
}
