@import '../scss/all';

$footer-min-height: rem(100);
$footer-slim-width: rem(400);

.Sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 2rem;
}

.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 101;
  pointer-events: none;

  @include min-width(bp(m)) {
    min-height: $footer-min-height;
  }

  // &::before,
  // &::after {
  //   position: absolute;
  //   content: "";
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   clip-path: $angle-medium-mobile;

  //   @include min-width(bp(m)) {
  //     clip-path: $angle-medium;
  //   }
  // }

  // &::before {
  //   min-height: $footer-min-height / 2.05;
  //   transition: background-color $base-transition-speed linear 100ms;

  //   @include min-width(bp(m)) {
  //     min-height: $footer-min-height * 1.15;
  //   }
  // }

  // &::after {
  //   min-height: $footer-min-height / 2.7;
  //   transition: background-color $base-transition-speed linear 100ms;

  //   @include min-width(bp(m)) {
  //     min-height: $footer-min-height / 1.05;
  //   }
  // }
}

// .FooterLarge {
//   height: 50%;
//   transform: scaleX(-1);
// }

// .FooterButtonCopy {
//   margin-top: $base-gutter;
//   pointer-events: initial;
//   margin-bottom: 0.5rem;

//   @include min-width(bp(m)) {
//     margin-bottom: 0;
//   }
// }

// .FooterWrapper {
//   margin-top: -20px;
//    @include min-width(bp(m)) {
//      margin-top: -75px;
//    }
// }

// Dashboard
.DashboardFooter {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.05rem;
  

  .UpperFooter {
    padding: 0px 0 0px 0;
    border-radius: 10px;
    overflow: hidden;

    .Container {
      text-align: center;
      padding: 40px;
      .Column {
        h5 {
          color: color(primary);
          font-size: 15px;
          font-weight: normal;
          margin-bottom: 20px;
          letter-spacing: 0.2em;
          line-height: 1;
          text-transform: uppercase;
        }

        ul {
          display: flex;
          flex-direction: column;
          list-style-type: none;
          li {
            display: inline-block;
            font-weight: normal;
            font-size: 15px;
            line-height: 1;
            margin-bottom: 20px;
            a, .ContactInfo {
              color: #4c5258;
              cursor: pointer;
              font-family: $font-family-body;
              line-height: 1;
              text-decoration: none;
              // transition: 0.25s;
              // &:hover {
              //   color: color(tertiary);
              // }
            }
            .ContactInfo {
              line-height: 1.5rem;
              cursor: text;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        @include min-width(bp(s)) {
          width: 30%;
        }
      }

      @include min-width(bp(s)) {
        display: flex;
        flex-direction: row;
        text-align: left;
      }
    }

    @include min-width(bp(s)) {
      padding: 0px 0;
    }
  }

  .LowerFooter {
    background-color: #003b5c;
    border-top: 1px solid #e5e7f2;
    color: #ffffff;
    padding: 12px 0;
    width: 100%;
    font-size: 12px;

    .Container {
      text-align: center;
      width: 100%;

      .LeftSide {
        opacity: 1;
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;

        @include min-width(bp(s)) {
          float: left;
          margin-left: 5%;
        }
      }

      .RightSide {
        display: flex;
        flex-direction: column;

        span {
          font-size: 13px;
          font-weight: 400;
          line-height: 25px;
          text-align: center;

          a {
            color: #ffffff;
            cursor: pointer;
            text-decoration: none;
            transition: 0.25s;
            @include min-width(bp(s)) {
              margin-left: 15px;
              line-height: 30px;
            }
          }
        }

        @include min-width(bp(s)) {
          flex-direction: row;
          float: right;
          margin-right: 5%;
        }
      }
    }
  }

  @include min-width(bp(s)) {
    
    margin-top: 35px;
  }
}
