@import "../scss/all";
@import "../Button/Button.module.scss";
@import "../Dashboard/Dashboard.module.scss";

.manageSubscriptionContainer.group.mt {
    max-width: 40rem;

    .subcriptionType {
        color: color(primary);
        font-size: 1.3rem;
        font-weight: $font-weight-medium;
        padding-top: .5rem;
    }
}

.onDemandMessage {
    text-align: center;
    margin-top: 1rem;

    .onDemandLink {
        color: color(black);
        text-decoration: underline;
    }
}