@import "../scss/all";

.OptionCarousel {
  margin: 0 auto;
}

.OptionCarouselSlide {
  text-align: center;
  outline: 0;
  cursor: pointer;
}

.OptionCarouselDesc {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}


.OptionCarouselSlideSelected {
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    content: '';
    border: rem(3) solid #FFC600;
    border-radius: 6px;
  }
}

.OptionCarouselTick {
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  background: #FFC600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  clip-path: $angle-small;
}