@import "../scss/all";

// body {
//   overflow: hidden;
// }

.CatalogContainer {
  background: url('../images/QrCode_landingpage_mobile.webp') top right no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  .Gradiant {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.2) 100%) repeat 0 0;
  }

  @include min-width(bp(m)) {
    background: url('../images/QrCode_landingpage_Desktop.webp') top right no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
}

.CatalogWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  z-index: 2;
  color: white;
  top: 56%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  width: 65%;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  @include min-width(bp(m)) {
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 20%;
  }

  @include min-width(bp(xl)) {
    left: 25%;
  }
}

.Title {
  line-height: .9;
  font-size: 48px;

  @include min-width(bp(s)) {
    line-height: 1.2;
    font-size: 64px;
  }
}

.CardContainer {
  display: block;
  align-self: center;
  text-align: center;

  @include min-width(bp(s)) {
    display: flex;
    flex-direction: column;
    width: calc(100%/3);
  }
}

.Card {
  margin: 0 1rem;
  line-height: 1.2;

  .StepNumber {
    position: relative;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 4px;
    border: 3px solid white;
    left: 50%;
    margin-left: -1rem;
    line-height: 1;
    font-size: 1.25rem;
    margin-bottom: 1rem;

    @include min-width(bp(s)) {
      font-size: 1.75rem;
      padding: 6px;
      width: 2.75rem;
      height: 2.75rem;
    }
  }

  h2 {
    font-weight: $font-weight-bold;
    font-size: 1.5rem;

    @include min-width(bp(s)) {
      font-size: 1.75rem;
    }
  }
}

.ButtonWrapper {
  margin-top: 1rem;
}