@import "../scss/all";

$input-height: rem(60);
$input-font-size: em(16);
$label-height: rem(11);

.Dropdown {
  appearance: none;
  background: transparent;
  border: rem(1) solid color(input-border-color);
  border-radius: 0.3rem;
  color: color(primary);
  display: block;
  font-family: $font-family-body;
  font-size: $input-font-size;
  min-height: $input-height;
  outline: 0;
  padding-left: 1rem;
  width: 100%;
  height: $label-height;

  &:focus {
    border: rem(1) solid color(tertiary);
    color: color(gray);
  }
}
