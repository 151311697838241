.ff-body { font-family: $font-family-body }
.ff-header { font-family: $font-family-heading }
.ff-sans-serif { font-family: sans-serif }

.fw-medium { font-weight: $font-weight-medium }
.fw-semibold { font-weight: $font-weight-semibold }
.fw-bold { font-weight: $font-weight-bold }

.bg-primary { background-color: color(primary); }
.bg-secondary { background-color: color(secondary); }
.bg-tertiary { background-color: color(tertiary); }

.c-primary { color: color(primary); }
.c-secondary { color: color(secondary); }
.c-tertiary { color: color(tertiary); }

.c-error { color: color(error); }

.c-white { color: color(white); }
.c-gray { color: color(gray); }

.db { display: block; }
.dt { display: table; }
.dib { display: inline-block; }
.vam { vertical-align: middle; }

.full-width {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.pa { padding: $base-gutter; }
.pa2 { padding: $base-gutter * 2; }

.pt { padding-top: $base-gutter; }
.pt2 { padding-top: $base-gutter * 2; }
.pt3 { padding-top: $base-gutter * 3; }
.pt4 { padding-top: $base-gutter * 4; }
.pt- { padding-top: $base-gutter / 2; }

.pl { padding-left: $base-gutter; }
.pl2 { padding-left: $base-gutter * 2; }
.pl- { padding-left: $base-gutter / 2; }

.pr { padding-right: $base-gutter; }
.pr2 { padding-right: $base-gutter * 2; }
.pr- { padding-right: $base-gutter / 2; }

.pb { padding-bottom: $base-gutter; }
.pb2 { padding-bottom: $base-gutter * 2; }
.pb- { padding-bottom: $base-gutter / 2; }

.mt { margin-top: $base-gutter; }
.mt2 { margin-top: $base-gutter * 2; }
.mt3 { margin-top: $base-gutter * 3; }
.mt4 { margin-top: $base-gutter * 4; }
.mt- { margin-top: $base-gutter / 2; }
.mt0 { margin-top: 0; }

.ml { margin-left: $base-gutter; }
.ml2 { margin-left: $base-gutter * 2; }
.ml- { margin-left: $base-gutter / 2; }

.mr { margin-right: $base-gutter; }
.mr2 { margin-right: $base-gutter * 2; }
.mr- { margin-right: $base-gutter / 2; }

.mb { margin-bottom: $base-gutter; }
.mb2 { margin-bottom: $base-gutter * 2; }
.mb4 { margin-bottom: $base-gutter * 4; }
.mb6 { margin-bottom: $base-gutter * 6; }
.mb7 { margin-bottom: $base-gutter * 7; }
.mb- { margin-bottom: $base-gutter / 2; }
.mb0 { margin-bottom: 0 !important; }

.mla { margin-left: auto }
.mra { margin-right: auto }
.m0 { margin: 0 }

.ttu { text-transform: uppercase }

.tac { text-align: center }

.tdu { text-decoration: underline }

.nb { border: 0; }
.nbr { border-radius: 0; }

.visuallyhidden {
  @include visuallyhidden;
}

.df { display: flex; }
.fdc { flex-direction: column; }
.fdcr { flex-direction: column-reverse; }
.aic { align-items: center; }
.jfc { justify-content: center; }
.jfe { justify-content: flex-end; }
.jfs { justify-content: space-between; }
.jfse { justify-content: space-evenly; }
.jsc { justify-self: center; }

// z-index
.zx1 {z-index: 1;}
.zx2 {z-index: 2;}

.por { position: relative; }
.poa { position: absolute; }

.ls1 { letter-spacing: rem(1) }

.strikethrough {
  text-decoration: line-through;
}

.tdn {
  text-decoration: none;
}

// br.lineBreak-lg | to work only on desktops.
.lineBreak-lg {
  @include max-width(bp(s)) {
    display: none;
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


@mixin link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.primary-link {
  @include link;
  color: color(primary);
}

.secondary-link {
  @include link;
  color: color(secondary);
}

.tertiary-link {
  @include link;
  color: color(tertiary);
}

.CalendarMonth {
  background: transparent !important;
}