$base-colors: (
  // Brand
  primary: #003b5c,
  secondary: #003b5c,
  tertiary: #c7d4e4,

  white: #FFFFFF,
  black: #222425,
  gray: #333333,
  light-gray: #c7d4e4,
  lighter: #D8D8D8,
  disabled: #888B90,
  input-border-color: #c7d4e4,
  yellow: #FFC600,
  
  success: #3A9B52,
  error: #DA0229,
);

$module-colors: (
  header-bg: color(accent),
  home-bg: color(accent),
);

$social-colors: (
  twitter:   #00ACED,
  facebook:  #3B5998,
  instagram: #D93175,
  gplus:     #CC3732,
  dribbble:  #EA4C89,
  pinterest: #CC2127,
  tumblr:    #44546B,
  vine:      #00B489,
  youtube:   #CD201F,
  medium:    #1A1919,
  vimeo:     #1AB7EA,
  linkedin:  #0077B4,
  spotify:   #1ED760,
);
