@import "../scss/all";

$file-input-ratio: 100%;

.FileUpload {
  border-radius: rem(18);
  border: rem(2) dashed color(input-border-color);
  cursor: pointer;
  display: block;
  padding-bottom: $file-input-ratio;
  position: relative;
  width: $file-input-ratio;

  &:hover {
    border-color: darken(color(input-border-color), 5%);
  }
}

.FileUploadLabel {
  position: relative;
}

.FileUploadActive {
  border: none;
  padding-bottom: 0
}

.FileUploadInput {
  display: none;
}

.FileUploadEdit {
  display: inline-block;
  margin-right: 10px;
}
