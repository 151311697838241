@import "../scss/all";

$label-font-size: em(11);
$label-font-weight: normal;
$label-height: rem(11);

$input-height: rem(60);
$input-font-size: em(15);

.FormInput {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.FormInputLabel {
  bottom: 0;
  color: color(light-gray);
  font-size: 15px;
  font-weight: normal;
  left: 1rem;
  position: absolute;
  top: 1.2rem;
  transform: translate(0);
  transition: opacity $base-transition-speed ease-in-out,
              transform $base-transition-speed ease-in-out;
  z-index: 1;
  order: 1;
}

.FormCheckboxLabel {
  margin-left: 30px;
  margin-bottom: -20px;
}

.FormInputField {
  border: rem(1) solid color(input-border-color);
  border-radius: .3rem;
  color: #003b5c;
  background-color: transparent;
  display: block;
  font-family: $font-family-body;
  font-size: $input-font-size;
  min-height: $input-height;
  padding: .6rem 0 0 1rem;
  outline: 0;
  width: 100%;
  order: 2;
  box-shadow: none;

  &.NoBorderBottom {
    border-bottom: 0;
  }

  &[value] {
    color: #003b5c;
    + .FormInputLabel {
      color: #003b5c;
      transform: translateY(-.85rem);
      opacity: .7;
    }
  }

  &[value=''] {
    + .FormInputLabel {
      color: #003b5c;
      transform: translateY(0);
    }
  }

  &::placeholder {
    opacity: 0;
  }
  
  &:hover {
    border: rem(1) solid color(tertiary);
  }

  &:focus {
    border: rem(3) solid color(tertiary);
    + .FormInputLabel {
      color: #003b5c;;
      transform: translateY(-.85rem);
    }
  }

  &.isReadOnly {
  
    &:hover {
      border: 0;
    }
  
    &:focus {
      border: 0;
    }
  }

  &.textAreaStyles {
    height: 10rem;
  }
}

.FormInputPasswordToggle {
  position: absolute;
  top: -20px;
  bottom: 0;
  margin: auto;
  height: 2rem;
  right: 20px;
  cursor: pointer;
}

.FormInputErrorField {
  border: rem(1) solid color(error);
}

.FormInputErrorIcon {
  position: absolute;
  top: $input-height / 3;
  bottom: 0;
  right: $base-gutter;
  margin: auto;
}

.FormInputErrorMessage {
  position: absolute;
  display: block;
  color: color(error);
  margin-top: $base-gutter / 2;
  font-weight: $font-weight-semibold;
  font-size: em(13);
  letter-spacing: .4px;
}

.FormInputAdditionalCopy {
  color: color(gray);
  font-size: em(13);
  margin-top: .3rem;
  order: 3;
  text-align: left;
}