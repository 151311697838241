@import '../scss/all.scss';

.Auth {
  padding-bottom: 40vh;
}

.SignIn {
  color: color(black);
  a {
    color: color(black);
  }
}