@import '../scss/all';

.ShippingAddress {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
}

.isFlex {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
