@import "../scss/all";

.Callout {
  padding-bottom: 20vh;
}

.CalloutWrapper {
  max-width: 32rem;
  position: absolute;
  left: 50%;
  text-align: center;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  & p {
    font-size: 1.4em;
    text-align: center;
  }
}

.CalloutTitle {
  padding: 0 2rem;
  
  span {
    font-weight: $font-weight-semibold;
  }
}
