@import "../scss/all";

.Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: color(white);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingSpinner {
  width: rem(32);
  height: rem(32);
  border: rem(4) solid color(secondary);
  border-left-color: color(tertiary);
  border-radius: rem(100);
  animation: spin 500ms linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
