@import "../scss/all.scss";

.carousel.carousel-slider {
  overflow: visible;

  &::before,
  &::after {
    width: 10%;
    height: 100%;
    background-image: linear-gradient(90deg, white, rgba(white, 0));
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &::after {
    right: 0;
    left: auto;
    background-image: linear-gradient(90deg, rgba(white, 0), white);
  }

  .control-arrow {
    top: 25%;
    bottom: auto;
    width: 3rem;
    height: 3rem;
    padding: 0;
    background-color: color(white);
    opacity: 1;
    box-shadow: 0 5px 15px rgba(#333333, 0.25);
    transform: translateY(-50%) scale(1);
    line-height: 0;
    border-radius: 100%;
    transition: transform $base-transition-speed ease-in-out,
      box-shadow $base-transition-speed ease-in-out;
    cursor: pointer;

    @include max-width(bp(m)) {
      display: none !important;
    }

    &:before {
      position: absolute;
      display: block;
      margin: 0;
      content: url("../images/caret.svg");
      border: none;
      top: 16px;
    }

    &:hover {
      background-color: color(white);
      box-shadow: 0 5px 15px rgba(#333333, 0.35);
    }

    &:active {
      background-color: color(white);
      transform: translateY(-50%) scale(0.95);
      box-shadow: 0 5px 15px rgba(#333333, 0.5);
    }
  }

  .control-disabled {
    display: block;
    cursor: default;

    &:hover {
      background-color: color(white);
      box-shadow: 0 5px 15px rgba(#333333, 0.25);
    }

    &:active {
      background-color: color(white);
      transform: translateY(-50%) scale(1);
      box-shadow: 0 5px 15px rgba(#333333, 0.25);
    }
  }

  .control-prev {
    left: -4rem;

    &:before {
      left: 16px;
      transform: rotate(180deg);
    }
  }

  .control-next {
    right: -4rem;

    &:before {
      right: 16px;
    }
  }
}

@include max-width(bp(s)) {
  .carousel {
    .slide {
      transition: opacity $base-transition-speed ease-in-out;
      &:not(.selected) {
        opacity: 0.5;

        img {
          transform: scale(.65);
        }
      }

      img {
        transition: transform $base-transition-speed ease-in-out;
      }
    }
  }
}