@import "../scss/all";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms linear 100ms;
  overflow: hidden;
  display: none;

  > * {
    pointer-events: none;
  }
}

.ModalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.6);
  z-index: 1001;
}

.ModalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: $base-gutter;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  transform: scale(0.7);
}

.ModalInner {
  background: color(white);
  border-radius: rem(6);
  bottom: 0;
  height: fit-content;
  max-height: 90%;
  left: 0;
  margin: $base-gutter auto;
  margin: auto;
  max-width: rem(600);
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 90%;
  z-index: 10000;
  opacity: 0;
  padding: $base-gutter * 2;
  transform: translate3d(0, 5%, 0);
  transition: transform 250ms ease-in-out 100ms,
              opacity 250ms linear 100ms;
}

.ModalShow {
  opacity: 1;
  pointer-events: all;
  display: flex;

  .ModalInner {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    overflow: auto;
  }
}


.ModalBtn {
  position: relative;
  width: 70%;
  margin: 0 auto;
  margin-top: $base-gutter * 4;
  padding-bottom: $base-gutter * 2;
}
