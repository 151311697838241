@import "../scss/all";
@import "../TextArea/TextArea.module.scss";


.group.mt.ReferralContainer {
    max-width: 45rem;
    margin-top: 0;

    .SectionHeader {

			h2 {
				color: color(black);
				font-size: 1.31rem;
			}
    }
}

.referrals {
	color: color(black);
	margin: 2rem 1rem 1rem 2rem;

    ol {
        list-style: decimal;
        
				li {
					font-size: 18px;
					line-height: 1.5;
					margin-bottom: 1rem;
					max-width: 600px;
        }
    }

    h2 {
        color: color(primary);
        font-family: $font-family-heading;
        font-size:  1.4rem;
        margin-left: -1rem;
    }

    p {
			font-size: 18px;
			line-height: 1.5;
        margin-left: -1rem;
    }
    
    .referralItem {
        font-family: $font-family-body;
        color: #333333;
        margin: .5rem 0;
        p {
            padding: 1rem 0;
        }
    }
    
    @include min-width(bp(xs)) {
        margin-left: 4rem;
    }
    
}

.textInput {
    border: 1px solid color(tertiary);
    border-right: none;
    resize: none;
    border-radius: 0.375rem 0 0 0.375rem;
    padding: $base-gutter;
    background-color: transparent;
    font-size: $textarea-font-size;
    font-family: $font-family-body;
    display: block;
    width: 100%;
    margin-top: $base-gutter / 2;
    outline: 0;
    // min-height: $textarea-min-height;
    color: color(gray);
  
    &::placeholder {
      color: color(gray);
    }
}

.copyBtn {
    border: 1px solid color(tertiary);
    border-left: none;
    resize: none;
    border-radius: 0 0.375rem 0.375rem 0;
    padding: $base-gutter;
    background-color: transparent;
    font-size: $textarea-font-size;
    font-family: $font-family-body;
    font-weight: 500;
    display: block;
    margin-top: $base-gutter / 2;
    outline: 0;
    color: color(primary);
  
    &::placeholder {
      color: color(gray);
    }
}

.referralLink {
    display: inline-flex;
    width: 100%;
}

.finePrint {
    border-top: 1px solid #D8D8D8;
    color: color(gray);
    font-size: 12px;
    margin: 4rem 1rem 1rem;
    
    p {
        margin-top: 1rem;
    }
    
    @include min-width(bp(xs)) {
        margin-left: 2.5rem;
    }
}