@import "../scss/all";

.SectionHeader {
  text-align: center;
  h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  p {
    color: color(black);
    font-weight: normal;
    margin: 0 auto;
    max-width: 32rem;
    text-align: center;
  }
}