@import '../scss/all';

$button-border-radius: rem(26);
$button-font-size: em(15);
$button-height: rem(50);
$button-width: rem(100);
$button-vertical-padding: rem(20);
$button-rounded-size: rem(55);
$button-radio-height: rem(50);
$button-radio-border-width: rem(1);
$button-preview-image-height: rem(300);
$button-preview-image-height-sm: rem(160);
$button-preview-title-height: rem(40);
$button-preview-font-size: rem(14);
$button-preview-border: rem(3);

.Button {
  align-items: center;
  appearance: none;
  background: inherit;
  border-radius: 100px;
  border: 0;
  box-shadow: none;
  color: color(white);
  cursor: pointer;
  pointer-events: initial;
  display: flex;
  font-family: $font-family-body;
  font-size: $button-font-size;
  font-weight: normal;
  justify-content: center;
  margin: 0 auto;
  min-height: $button-height;
  max-width: 22rem;
  outline: 0;
  letter-spacing: em(1);
  padding: 0 $button-vertical-padding;
  position: relative;
  text-align: center;
  transition: transform $base-transition-speed ease-in-out,
    border-color $base-transition-speed ease-in-out,
    background-color $base-transition-speed ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &.ActiveState {
    border: 3px solid color(tertiary);
    background-color: color(tertiary);
  }

  &:disabled {
    background-color: color(disabled);

    .Arrow {
      display: none !important;
    }
  }

  &:hover {
    .Arrow {
      @include min-width(bp(s)) {
        display: inline-block;
        opacity: 1;
        transition: all 150ms;
      }
    }
  }
}

.Arrow {
  display: none;
  opacity: 0;
  margin-right: -18px;
  padding-left: 18px;

  @include min-width(bp(s)) {
    transition: all 150ms;
  }
}

.requiredQuestion {
  color: color(primary);
  margin-bottom: 1rem;
  text-align: center;
}

// Color Variations
.ButtonPrimary {
  background-color: color(primary);
}

.ButtonSecondary {
  background-color: color(secondary);
  color: color(black);
}

.ButtonTertiary {
  background-color: color(tertiary);
}

.ButtonWhite {
  background-color: color(white);
}

.ButtonSmall {
  min-height: calc(#{$button-height} / 1.5);
}

.ButtonInner {
  display: inline-block;
  text-align: center;
  font-weight: normal;
}

.ButtonCount {
  justify-content: space-evenly;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.ButtonCountText {
  width: 35%;
  min-height: calc(#{$button-height} / 1.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(#d8d8d8, 0.25);
  font-size: em(20);
}

.ButtonClear {
  background-color: none;
}

.ButtonInverted {
  color: color(primary);
  background-color: transparent;
  border: 1px solid color(primary);
}

.ButtonWhiteInverted {
  color: color(white);
  background-color: color(transparent);
  border: 2px solid color(white);
  font-weight: bold;
  font-size: 1.3rem;

  &:hover {
    background-color: color(white);
    color: #003b5c;
  }
}

.Back {
  color: color(black);
  width: fit-content;
  padding: 0;
  margin: 0 auto;
  span {
    text-decoration: underline;
  }
}

.ButtonRadio {
  background-color: color(transparent);
  border: $button-radio-border-width solid color(light-gray);
  border-radius: 6px;
  color: color(primary);
  min-height: rem(50);
  text-transform: none;
  letter-spacing: normal;

  &:hover {
    border: $button-radio-border-width solid color(tertiary);
  }

  &:active {
    border: 3px solid color(tertiary);
  }
}

.LeftAlign {
  margin: 0;
}

.ButtonInput {
  display: none;

  &:focus {
    ~ .SuggestedItem {
      background-color: color(tertiary);
    }
  }

  &:checked {
    ~ .ButtonRadio {
      background-color: color(tertiary);

      &.Sizes {
        background-color: color(tertiary);
      }
    }

    ~ .SuggestedItem {
      background-color: color(white);
    }

    ~ .ButtonPreviewImage {
      border: 3px solid color(tertiary);
      .CheckMark {
        background-color: color(tertiary);
        border-color: color(tertiary);
        border-radius: 50%;
        display: block;
        top: 1rem;
        right: 1rem;
        left: auto;
        height: 1.5rem;
        width: 1.5rem;

        &::after {
          display: block;
        }
      }
    }
  }

  @include min-width(bp(s)) {
    ~ .SuggestedItem {
      min-width: 219px;
    }

    &:checked {
      ~ .ButtonPreviewImage {
        .CheckMark {
          top: 1.2rem;
          right: 1.2rem;
          height: 2rem;
          width: 2rem;

          &::after {
            left: 10px;
            top: 5px;
            width: 8px;
            height: 14px;
          }
        }
      }
    }
  }
}

.ButtonPreviewImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: em(1) solid color(light-gray);
  min-height: $button-preview-image-height;
  padding: 0.7rem;
  position: relative;

  @include max-width(bp(s)) {
    min-height: 14.6rem;
  }

  .CheckMark {
    display: none;
  }

  &.ColorPreviewImage {
    padding: 0.7rem 20% 0;
  }

  &:active {
    border: 3px solid color(tertiary);
  }

  .ButtonRadio {
    &:hover {
      border: none;
    }

    &:active {
      border: none;
    }
  }

  @include min-width(bp(m)) {
    &:hover {
      border: $button-radio-border-width solid color(tertiary);
    }
  }
}

.AspectRatio {
  padding-top: calc(var(--aspect-height) / var(--aspect-width) * 100%);
  width: 100%;
  position: relative;
  min-height: 0;
}

.ColorPreviewImage {
  border: 0;
  min-height: 0;

  &:checked {
    border: 0;
  }

  label {
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.1875rem transparent,
      0 0.125rem 1.25rem 0 rgba(51, 51, 51, 0.1);
    min-height: 1rem;
    min-width: 1rem;
  }
  
  .AspectRatio {
    padding-top: calc(1 / 1.3 * 100%);
  }

  @include min-width(bp(xs)) {
    .AspectRatio {
      padding-top: calc(1 / 1.1 * 100%);
    }
  }
}

.ButtonPreviewTitle {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  padding: 5px 0;
  text-align: center;
}

.CustomCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: $font-family-body;
  font-size: 18px;
  font-weight: normal;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .CheckMark {
        background-color: color(black);
        border-color: color(black);

        &::after {
          display: block;
        }
      }
    }
  }
}

.CheckMark {
  border: $button-radio-border-width solid color(light-gray);
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;

  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 2px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
}

.ButtonText {
  font-family: $font-family-body;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  letter-spacing: 1px;
  color: color(tertiary);
  margin: 0.7rem 0;
  border: none;
  background: none;
  cursor: pointer;
}

.ButtonTextBlack {
  font-family: $font-family-body;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  letter-spacing: 1px;
  color: color(black);
  margin: 0.7rem 0;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.ButtonNavDark {
  font-family: $font-family-body;
  font-weight: $font-weight-bold;
  font-size: 16px;
  letter-spacing: 0.53;
  color: color(white);
  margin: 0.7rem 0;
  // border: 1px solid black;
  background: none;
}
