@import "../scss/all";

.profileContainer {
    @media (min-width: 768px) {
        max-width: 30rem;
        margin-left: auto;
        margin-right: auto;
    }
    @media (min-width: 1536px) {
        max-width: 45rem;
    }
}
.optionsContainer {
    z-index: 999;
    position: relative;
}

.optionCard {
    background-color: transparent;
    border: 1px solid #c7d4e4;
    border-radius: 4px;
    height: 4rem;
    margin: 1.3rem 0;
    overflow: hidden;
    padding: 1rem;
    cursor: pointer;
    .optionImgDiv {
        width: 15%;
        position: relative;
        .optionImg {
            width: 75%;
            height: auto;
            float: left;
            margin-right: 1rem;
        }
    }
    .optionText {
        display: flex;
        float: left;
        padding-top: .5rem;
        font-size: 18px;
    }
    .optionProgress {
        height: 30px;
        width: 30px;
        float: right;
        .badge {
            position: relative;
            left: -33px;
            top: 5px;
            background-color: color(tertiary);
            height: 22px;
            width: 65px;
            border-radius: 4px;
            text-align: center;
            .badgeCTA {
                color: #FFFFFF;
                font-family: "Proxima Nova", sans-serif;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                display: inline-block;
            }
        }
        
    }
    @media (min-width: 1536px) {
        height: 6rem;
        .optionProgress {
            height: 40px;
            width: 40px;
            margin: 1rem;
        }
    }
}

// reshuffle
.shuffle-container {
    margin: 0 auto;
    text-align: center;
    width: 21rem;
}

.shuffle-incomplete {
    font-family: $font-family-heading;
    font-weight: $font-weight-semibold;
    font-size: 1.5rem;
    letter-spacing: .02rem;
    p {
        font-weight: normal;
        font-size: 18px;
    }
}

.shuffle-complete {
    padding: 0 2rem;
    max-width: 350px;
    margin: 0 auto;
    
    p {
        font-weight: $font-weight-light;
        margin: 2rem 0;
    }

    img {
        width: 4rem;
    }
}

.reshuffle-cards {
    display: none;
}