@import "../scss/all";

h2.fw-medium.ff-body {
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    text-align: left;
    margin-top: 4rem;
}

.QuestionTitle {
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    text-align: left;
    margin-top: 4rem;
}

.QuestionTitleHidden {
    display: hidden;
}
  
.QuestionSubTitle {
    font-family: $font-family-body;
    font-weight: $font-weight-semibold;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;

}