@import "../scss/all";

.CheckoutSuccess {
  padding-bottom: 20vh;
  padding-top: 100px;
  text-align: center;
}

.CheckoutSuccessHeader {
  text-align: center;
}

.CheckoutWrapper {
  max-width: rem(500);
  margin-left: auto;
  margin-right: auto;
  margin-top: rem(-40);

  p {
    font-family: $font-family-body;
    font-weight: normal;
    text-align: center;
  }
}

.CheckougTitle {
  @include font-sizes(20, 36);
}
