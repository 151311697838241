@import '../scss/all';

.DashboardSubHeader {
  margin-top: 3rem;
  h1 {
    font-size: 22px;
  }

  h2 {
    color: color(black);
    font-size: 16px;
    font-family: $font-family-body;
    margin: 0 auto;
    max-width: 32rem;
    margin-bottom: 1.4rem;

    &.fullWidth {
      max-width: 100%;
    }
  }
}

.DashboardHeader {
  margin: 2rem 0 2rem 0;
  position: relative;
  z-index: 3;

  h1 {
    font-size: 24px;
  }

  h2 {
    color: color(black);
    font-size: 16px;
    margin: 0 auto;
    max-width: 32rem;
  }
  img {
    margin: 0.5rem 0 -1rem 0;
  }

  &.FontSizeLarge {
    h1 {
      font-size: 1.75rem;
    }

    h2 {
      color: color(black);
      font-size: 18px;
      max-width: 100%;
    }
  }

  &.SansSerif {
    h2 {
      color: color(black);
      font-family: sans-serif;
      font-size: 16px;
    }
  }
}

.DashboardGrid {
  background-color: transparent;
  position: relative;
  z-index: 4;
  img {
    height: 90px;
    margin: 0.8rem 0 0 0;
  }
  @media (min-width: 768px) {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(9, 1fr);
    grid-auto-rows: 130px;
    width: 100%;
    img {
      margin: 0;
    }
    .c1 {
      grid-area: 1 / 1 / span 2 / span 3;
      margin-top: 0px;
      img {
        height: 90px;
      }
    }
    .c2 {
      grid-area: 1 / 4 / span 2 / span 3;
      margin-top: 0px;
      img {
        height: 90px;
      }
    }
    .currentOrder {
      grid-area: 1 / 1 / span 2 / span 6;
    }
    .c3 {
      grid-area: 1 / 7 / span 2 / span 3;
      img {
        height: 90px;
      }
    }
    .c4 {
      grid-area: 3 / 1 / span 2 / span 3;
      img {
        height: 90px;
      }
    }
    .c5 {
      grid-area: 3 / 4 / span 2 / span 3;
      img {
        height: 90px;
      }
    }
    .c6 {
      grid-area: 3 / 7 / span 2 / span 3;
      img {
        height: 90px;
      }
    }
  }
  @media (min-width: 1024px) {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 130px;
    .c1 {
      grid-area: 1 / 1 / span 2 / span 4;
    }
    .c2 {
      grid-area: 1 / 5 / span 2 / span 4;
    }
    .currentOrder {
      grid-area: 1 / 1 / span 2 / span 8;
    }
    .c3 {
      grid-area: 1 / 9 / span 2 / span 4;
    }
    .c4 {
      grid-area: 3 / 1 / span 2 / span 4;
    }
    .c5 {
      grid-area: 3 / 5 / span 2 / span 4;
    }
    .c6 {
      grid-area: 3 / 9 / span 2 / span 4;
    }
    .c7 {
      grid-area: 3 / 9 / span 2 / span 3;
    }
    .Welcome {
      grid-area: 1 / 1 / span 1 / span 10;
      background-color: transparent;
    }
  }
}

.dashboardWrapper {
  @media (min-width: 320px) {
    z-index: 1;
    display: flex;
    width: 100%;
  }
}

.tile {
  border: 1px solid #c7d4e4;
  // background-color: #e9e6e0;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  z-index: 101;
  height: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;

  h3 {
    font-weight: $font-weight-medium;
    font-size: 20px;
    line-height: 1.5;
  }

  h4 {
    color: color(black);
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    margin: -0.5rem 1rem 1rem;
  }

  .SubscriptionType {
    font-family: $font-family-heading;
    font-weight: normal;
    font-size: 15px;
    font-style: italic;
  }

  @media (min-width: 768px) {
    margin-bottom: auto;
    padding-top: 2.7rem;

    h4 {
      margin: 1 1rem;
    }
  }
}

.coreTileImgDiv {
  height: 30%;
}

.tileIcon {
  width: 100%;
  max-height: 100%;
}

.iconNoCTA {
  width: 100%;
  height: 110%;
}

.textNoCTA {
  text-align: center;
  padding: 1rem 0;
}

.coreTile {
  border: 1px solid #c7d4e4;
  border-radius: 4px;
  cursor: pointer;
  z-index: 101;
  height: 100%;
  margin-bottom: 1rem;

  .coreTileText {
    text-align: center;
    padding-top: 1rem;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
    button {
      margin-bottom: 0.5rem;
    }
  }

  .additionalOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    button {
      margin: 0;
      padding: 0 10;
      width: auto;
      &.ButtonInner {
        text-decoration: underline;
      }
    }
  }

  h3 {
    font-weight: $font-weight-medium;
    font-size: 20px;
    line-height: 1.5;
  }

  h4 {
    color: color(black);
    font-size: 15px;
    line-height: 1.5;
    margin: 0 auto 1rem;
    max-width: 32rem;
  }

  button {
    width: 80%;
    margin: 0 auto;
  }
}

.styleProfileIcon {
  @media (min-width: 768px) {
    width: 30%;
    height: auto;

    img {
      width: 100%;
    }
  }
}

/* header*/
.Welcome {
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 80px;
  
  margin: 10px 0px 20px 0px;
  border-radius: 10px;

  h1 {
    font-size: 21px;
    position: absolute;
    font-weight: $font-weight-medium;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.marginTop {
      top: 40%;
    }
  }

  .passMember {
    position: absolute;
    font-size: 16px;
    font-weight: $font-weight-medium;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    a {
      color: #003b5c;
    }
  }

  img {
    display: block;
    width: 50rem;
    height: 100%;
    margin: 1rem 0;
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 30px;
    }

    img {
      width: 80rem;
    }
  }
}
