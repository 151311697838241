@import "../scss/all";


.customOrderCTA {
    margin: 3rem auto 0 auto;
    max-width: 20rem;
}



