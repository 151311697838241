@import '../scss/all';

.ReviewItem {
  margin-bottom: $base-gutter;

  .DrtgReviewItemHr {
    border-bottom: rem(1) solid color(lighter);
    width: 67%;
    margin: 0 auto;
  }

  &:last-of-type {
    .DrtgReviewItemHr {
      border-bottom: 0;
    }
  }
}

.SuggestedItem {
  margin-left: 3rem;
  .SuggestedItemHeader {
    font-size: 22px;
  }
  .SuggestedItemAnnoucement {
    font-family: $font-family-body;
    font-size: 1rem;
    font-style: italic;
    line-height: 19px;
    margin-bottom: 1.375rem;
  }
}

.Drtg {
  left: 7rem;
  position: relative;
}

.ReviewItemHr {
  border-bottom: rem(1) solid color(lighter);
}

.ReviewItemInner {
  width: 100%;
}

.ReviewItemWrapper {
  margin-bottom: $base-gutter;
}

.ReviewItemImage {
  margin-right: $base-gutter;
  border-radius: rem(6);
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
}

.ReviewItemInfo {
  display: inline-block;
  vertical-align: top;

  .DiscountIcon {
    width: 2.3rem;
  }
}

.ReviewItemDesc {
  margin-top: $base-gutter / 2;
  margin-bottom: 0;
  font-family: $font-family-body;
  font-weight: normal;
  font-size: em(16);
}

.ReviewItemBrand {
  font-size: em(14);
  text-transform: uppercase;
  font-weight: normal;
  color: color(gray);
  letter-spacing: rem(1);
  margin-top: $base-gutter / 2;
  margin-bottom: $base-gutter / 2;
}

.ReviewItemSize {
  font-size: em(14);
  text-transform: uppercase;
  letter-spacing: em(1);
  color: color(gray);
  font-weight: normal;
}
