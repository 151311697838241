@import '../scss/all';

.StylistNote {
  .OutfitTitle {
    font-size: em(24);
  }
  img {
    margin-right: $base-gutter;
    border-radius: rem(6);
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: top;
  }
  p {
    margin-top: $base-gutter / 2;
    margin-bottom: 0;
    font-family: $font-family-body;
    font-weight: normal;
    font-size: em(16);
    // &.LineItemDescription {
    //   min-height: em(42);
    // }
  }
}
