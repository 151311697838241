$base-gutter: 13px;
$base-mobile-width: 380;
$base-tablet-width: 800;
$base-desktop-width: 1280;

$angle-small: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
$angle-medium: polygon(0 50%, 100% 0, 100% 100%, 0 100%);
$angle-medium-mobile : polygon(0 40%, 100% 0, 100% 100%, 0 100%);

$quiz-width: 671;
