@import '../scss/all';

.flowOptionContainer {
  position: relative;
  max-height: 220px;
  text-align: center;

  .Icon {
    height: 90px;
  }

  h4 {
    color: color(black);
    margin: 0 auto;
    max-width: 280px;
    margin-bottom: 1rem;
  }

  Button {
    background-color: transparent;
    border: rem(1) solid color(light-gray);
    border-radius: 6px;
    color: color(black);
    min-height: rem(50);
    min-width: 12rem;
    position: relative;
    text-transform: none;
    letter-spacing: normal;

    &:hover {
      border: rem(1) solid color(tertiary);
    }

    @include min-width(bp(s)) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
