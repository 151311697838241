@import "../scss/all";

$angle-header-height: 6rem;

.AngleHeader {
  background: extractVar(#{--angle-color-secondary});
  position: relative;
  padding-bottom: $angle-header-height;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 81%);
  height: extractVar(#{--angle-height});

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: extractVar(#{--angle-color});;
    content: "";
    clip-path: polygon(0 0, 100% 0, 100% 40%, 0 61%);
  }
}
