@import "../scss/all";

.NavBar {
  border-bottom: 1px solid #c7d4e4;
  background-color: #003b5c;
  height: 90px;
  position: relative;
  padding-top: 10px;
}

.logo {
  width: 130px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  margin-left: 5%;
  cursor: pointer;

  // @media (max-width: 511px) {
  //   width: 100px;
  // }

  &.CenterLogo {
    left: 50%;
    margin-left: -60px;
  }

  // &.DRTGLogo {
  //   width: 180px;
  //   margin-left: -90px;
  // }
}

.navLinks {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  float: right;
  margin-right: 5%;
  cursor: pointer;

  @media (max-width: 511px) {
    display: none;
  }

  li {
    font-family: $font-family-body;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: .53px;
    padding: 1.2rem 1rem 1rem 1rem;
  }
}

.navIcons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  float: right;
  margin-right: 5%;
  
  @media (min-width: 512px) {
    display: none;
  }
}

.myProfile {
  width: 28px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 85px;
  margin: auto;
}

.logOut {
  width: 28px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  margin-left: 1rem;
  margin-right: 1rem;
}

