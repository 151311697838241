@import "../scss/all";

.Quiz {
  padding-bottom: 20vh;
  position: relative;
}

.QuizName {
  position: fixed;
  top: 20px;
  left: 30px;
  color: white;
}

.DrtgPaymentInfo {
  width: 6rem;
}

.Longform {
  .hello {
    color: blue;
  }

  h2.fw-medium.ff-body {
    font-size: 40px;
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    text-align: right;
    margin-top: 4rem;
  }
}
