@import "../scss/all";

$card-border-width: rem(10);
$card-ratio: 150%; // 315px x 345px
$card-padding: 10%;

.SwipeableCard {
  position: relative;
  max-width: 14rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  
  @include min-width(bp(s)) {
    margin-bottom: 6rem;
    max-width: 18rem;
  }
}

.SwipeableCardList {
  position: relative;
  width: 100%;
  padding-bottom: $card-ratio;
}

.SwipeableCardItem {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  transition: opacity 350ms linear;
  opacity: 0;

  &:last-of-type {
    opacity: 1;
    z-index: 1;
  }

  > img {
    width: 100%;
    height: auto;
    display: none;
  }
}

.SwipeableCardFooter {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    color: color(black);
    background-color: transparent;
    border: em(1) solid color(light-gray);
    border-radius: 6px;
    font-weight: normal;
    font-size: 15px;
    margin-top: 1rem;
    width: 6.5rem;
    min-height: 3rem;
    
    &:hover {
      border: em(1) solid color(black);
    }
    
    &:active {
      background-color: transparent;
    }
  
    @include min-width(bp(s)) {
      margin-top: 1.28rem;
      min-height: 3.5rem;
    }
  }
}

.SwipeableCardItemLeft {
  opacity: 0 !important;
  transform: translate3d(-20%, 0, 0);
  transition: opacity $base-transition-speed linear 100ms,
              transform $base-transition-speed linear 100ms;
}

.SwipeableCardItemRight {
  transition: opacity $base-transition-speed linear 100ms,
              transform $base-transition-speed linear 100ms;
  opacity: 0 !important;
  transform: translate3d(20%, 0, 0);
}

